@import url('https://fonts.googleapis.com/css2?family=Alata&display=swap');

/* src/styles/LinksPage.css */
.links-page {
    display: flex;
    text-align: center;
    flex-direction: column;
    background: url('../assets/images/backLinks.png');
    background-size: cover;
    height: 100vh;
}

.links-header-logo {
    font-family: Alata, sans-serif;
    display: flex;
    align-content: center;
    margin: 0 auto 20px;
}

.links-header-logo img {
    /* Ajustez ces styles selon vos besoins */
    width: 100px;
    height: auto;
}

.links-list {
    margin-top: 20px;
}

.link-item {
    width: 90%;
    margin: auto;
    text-decoration: none;
    display: flex;
    align-items: left;
    justify-content: left;
    margin-bottom: 20px;
    color: #202020;
    background-color: #fff;
    border-radius: 15px;
    padding: 10px 0;
}

.link-item img {
    /* Ajustez la taille des logos des liens */
    width: 40px;
    height: auto;
    margin-right: 10px;
    margin-left: 10px;
}

.link-item span {
    font-size: 20px;
    margin: auto auto auto 0;
    font-family: Alata, sans-serif;
}
