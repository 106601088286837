/* src/styles/index.css */
@import url('https://fonts.googleapis.com/css2?family=Alata&display=swap');
/* src/styles/Header.css */
/* src/styles/Header.css */
.footer {
    display: flex;
    height: 50px;
    background: #2B2B2B;
    z-index: 50;
    position: relative;
  }

  .footer-link a{
    color: white;
    font-family: sans-serif;
    margin: auto 10px;
    font-size: 15px;
    display: flex;
    text-decoration: none;
  }
  

  .footer-link img {
    width: 20px;
    margin: auto;
    margin-right: 5px;
  }