/* src/styles/index.css */
@import url('https://fonts.googleapis.com/css2?family=Alata&display=swap');
/* src/styles/Header.css */
/* src/styles/Header.css */
.header {
    z-index: 2;
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow: hidden;
}

.header .parallaxBanner {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
}

.header .header-leaf-container {
    z-index: 0;
    position: relative;
    width: 100%;
    height: 100%;
}
.header .header-leaf {
    position: absolute;
}
.header .header-leaf-1 {
    right: 0;
    bottom: 0;
}

.header .header-leaf-2 {
    top: 0;
    left: 10%;
}

.header .header-leaf-3 {
    top: 0;
    right: 0;
}

.header .header-leaf-4 {
    bottom: 0;
    left: 4%;
}

.header .header-leaf-5 {
    top: 0;
    left: 50%;
}

.header .header-leaf-6 {
    top: 20%;
    left: 0;
}

.header .header-leaf-7 {
    bottom: -40px;
    left: 50%;
}

.layer-midground {
    z-index: 1;
    background-size: contain !important;
}

.layer-foreground {
    z-index: 2;
    background-repeat: no-repeat;
    background-size: contain !important;
    width: 55%;
    inset: 0 25% !important;
    background-position: bottom center !important;
    /* Ajustez la taille ici si nécessaire */
}

.header-top {
    position: absolute;
    display: flex;
    align-items: center;
    top: 0;
    height: 20vh; /* 90% de la hauteur de la vue */
    justify-content: center;
    flex: 0 0 auto;
    z-index: 3;
}

.header-logo {
    /* Ajustez selon la taille souhaitée */
    width: 150px; /* Exemple de taille de logo */
}

.header-title {
    font-family: 'Alata', sans-serif;
    font-size: 60px;
    margin: auto;
    padding-right: 150px;
}

.header-banner {
    z-index: 2;
    width: 100%;
    height: 80vh;
    object-fit: cover; /* Couvre l'espace sans déformer l'image */
    object-position: top;
    flex-grow: 1; /* Permet à la bannière de remplir l'espace restant */
}

/* Vous pouvez ajouter des media queries si vous souhaitez ajuster la grille pour différents points d'arrêt */
@media (max-width: 1000px) {
    .header {
        height: 50vh;
    }
    .header-title {
        font-size: 25px;
        margin: auto;
        padding-right: 100px;
    }

    .header-top {
        height: 20%;
    }
    .header-logo {
        /* Ajustez selon la taille souhaitée */
        width: 100px; /* Exemple de taille de logo */
    }

    .header .header-leaf {
        width: 15%;
    }
    .layer-foreground {
        inset: 7% 25% !important;
    }
}
@media (max-width: 800px) {
    .header .header-leaf {
        width: 20%;
    }
    .layer-foreground {
        width: 65%;
        inset: 7% 20% !important;
        background-position: bottom center !important;
    }
}
@media (max-width: 600px) {
    .header .header-leaf {
        width: 25%;
    }
    .layer-foreground {
        width: 75%;
        inset: 7% 15% !important;
        background-position: bottom center !important;
    }
}
