.big-container {
    position: relative;
}

.big-container .products-section {
    z-index: 2;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    grid-gap: 20px;
    padding-top: 50px;
    padding-bottom: 50px;
    justify-items: center;
    height: fit-content;
}

/* Vous pouvez ajouter des media queries si vous souhaitez ajuster la grille pour différents points d'arrêt */
@media (max-width: 600px) {
    .products-section {
        grid-template-columns: repeat(
            auto-fill,
            minmax(400px, 1fr)
        ); /* Colonne plus petite pour les écrans étroits */
    }
}
